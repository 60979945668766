import { useQuery, UseQueryResult } from 'react-query';

import { getUserTimestamps, UserTimestampsResponse } from '../users';
import { QueryKeys } from '../enums';

export const useUserTimestamps = ({
  refetchInterval,
  enabled,
  refetchOnMount,
}: {
  refetchInterval?: number | false | undefined | ((data: UserTimestampsResponse | undefined) => number | false);
  enabled?: boolean;
  refetchOnMount?: boolean | 'always';
}): UseQueryResult<UserTimestampsResponse, unknown> =>
  useQuery(QueryKeys.UserTimestamps, getUserTimestamps, {
    refetchInterval: refetchInterval ?? false,
    enabled: enabled ?? true,
    refetchOnMount: refetchOnMount ?? true,
    notifyOnChangeProps: ['data', 'isError', 'isLoading'],
  });
