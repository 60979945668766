/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import { PayloadAction } from 'typesafe-actions';

import { AnalyticsAction, AnalyticsService, Bloomreach } from '@investown/fe/common-utils';

import { ExponeaWebType } from './types';
import { createInvestmentViewPayload } from './bloomreachEvents';

const exponea = (window as any).exponea as ExponeaWebType;

export const createBloomreachWebAnalyticsService = (): AnalyticsService => {
  let userProperties: Record<string, any> = {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let isUserIdentified: boolean;

  return {
    init: () => {
      exponea.start();
    },
    setUser(payload) {
      // To set up custom IDs, they need to be defined in the Bloomreach Engagement project
      // (Administration -> Projects -> Your project -> Identifiers)
      // https://documentation.bloomreach.com/engagement/docs/tracking#custom-ids
      const { user_id, email, ...userData } = Bloomreach.createUserIdentifiersPayload(payload);
      exponea.identify(
        { user_id, email: payload.email },
        userData,
        () => {
          isUserIdentified = true;
          if (Object.keys(userProperties).length > 0) {
            exponea.update(userProperties);
            userProperties = {};
          }
        },
        (error) => {
          console.error(`Bloomreach: Error setting user`, error);
        }
      );
    },
    resetUser: () => {
      exponea.anonymize();
      isUserIdentified = false;
    },
    trackScreenView: (): void => {
      // Do not track this event
    },
    trackEvent: (action): void => {
      switch (action.type) {
        case AnalyticsAction.INVEST_OVERVIEW: {
          const payload = createInvestmentViewPayload(action);

          exponea.track(
            Bloomreach.Events[AnalyticsAction.INVEST_OVERVIEW],
            payload,
            undefined,
            getErrorLogger(`Error tracking event ${action.type}`)
          );
          break;
        }
        case AnalyticsAction.VIEW_ITEM:
        case AnalyticsAction.NOTIFICATION_PREFERENCES:
        case AnalyticsAction.AUTOINVEST_ACTIVATED:
        case AnalyticsAction.AUTOINVEST_DEACTIVATED:
        case AnalyticsAction.AUTOINVEST_PARAMETERS_CHANGED:
        case AnalyticsAction.AUTOINVEST_STEP:
        case AnalyticsAction.AUTOINVEST_BANK_DETAILS_COPIED: {
          // Do not track this event
          break;
        }
        default:
          exponea.track(
            action.type,
            (action as PayloadAction<string, any>).payload || {},
            undefined,
            getErrorLogger(`Error tracking event ${action.type}`)
          );
      }
    },
  };
};

function getErrorLogger(message: string): (error: any) => void {
  return function logError(error: any): void {
    console.error(message, error);
  };
}

export const getOperatingSystem = (): string => {
  let os = 'Unknown OS';

  if (navigator.userAgent.includes('Win')) os = 'Windows';
  else if (navigator.userAgent.includes('Mac')) os = 'MacOS';
  else if (navigator.userAgent.includes('Linux')) os = 'Linux';
  else if (navigator.userAgent.includes('Android')) os = 'Android';
  else if (navigator.userAgent.includes('like Mac')) os = 'iOS';

  return os;
};
