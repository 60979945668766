/* eslint-disable no-use-before-define */
import React, { FC, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useIdleTimer } from 'react-idle-timer';

import MainApp from './MainApp';

import { userIsActive, userIsIdle } from 'appRedux/userInteraction/actions';
import { getCurrentAppLocaleObject, initLocalStorageLocale } from 'lngProvider';
import useMobileScreenRedirect from 'hooks/useMobileScreenRedirect';
import { PATH_MOBILE } from 'routes/routesPaths';

interface SettingsProps {
  settings: { locale: string };
}
const App: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useIdleTimer({
    timeout: 600000, // 10 minutes
    onIdle: () => dispatch(userIsIdle()),
    onActive: () => dispatch(userIsActive()),
    debounce: 500,
  });

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  initLocalStorageLocale();
  const currentAppLocale = getCurrentAppLocaleObject();
  useMobileScreenRedirect({
    screenWidthInPixels: '1280px',
    onShowMobileScreen: () => history.push(PATH_MOBILE),
    actualLocation: location.pathname,
  });

  // temporary fix for renamed project (slug was fixed later)
  // TODO: remove later
  let url = location.pathname;
  if (url === '/property/praha-podoli-bytovy-dum-praha-holubova-1:-4.-etapa-962') {
    url = '/property/praha-bytovy-dum-praha-holubova-1:-4.-etapa-962';
    history.push(encodeURI(url));
  }

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore update react-helmet */}
        <Helmet>
          <html lang={currentAppLocale.locale} />
        </Helmet>
        <Route path={`${location.pathname}`} component={MainApp} />
      </IntlProvider>
    </ConfigProvider>
  );
};

const mapStateToProps = (props: SettingsProps) => {
  const { locale } = props.settings;
  return { locale };
};
export default connect(mapStateToProps)(App);
