import React, { FC } from 'react';
import styled from 'styled-components';

import Topbar from 'components/V2/Topbar/Topbar';
import App from 'routes/index';
import { ConditionalWrapper } from 'components/V2/Topbar/ConditionalWrapper';
import Footer from 'components/V2/Footer/Footer';

const MainApp: FC = () => (
  <Layout>
    <ContentWrapper>
      <ConditionalWrapper>
        <Topbar />
      </ConditionalWrapper>
      <App />
      <ConditionalWrapper>
        <Footer />
      </ConditionalWrapper>
    </ContentWrapper>
  </Layout>
);

const Layout = styled.section`
  position: relative;
  background: none;
  box-sizing: border-box;
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
`;

const ContentWrapper = styled.main`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  // Adding the margin from bottom so Footer will fit better and not cover the content
  margin-bottom: 66px;
`;
export default MainApp;
