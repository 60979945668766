/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from 'typesafe-actions';
import TagManager from 'react-gtm-module';

import { AnalyticsAction } from '../analyticsActions';
import { hashSHA256 } from '../analyticsHelpers';
import { AnalyticsService } from '../analyticsService';
import { getItemPurposesPayload } from '../Firebase/firebaseEvents';

import { GTMDataLayers, googleTagManager } from './GtmUtils';
import { createAddToCartEvent, createPurchaseEvent, createRegistrationEvent, createWithdrawEvent } from './gtmEvents';

export const createGTMService = (): AnalyticsService => {
  return {
    init: () => {
      const gtmId = process.env.REACT_APP_GTM_ID;
      if (!gtmId || gtmId === '') {
        console.warn('Missing REACT_APP_GTM_ID env variable');
        return;
      }
      TagManager.initialize({
        gtmId,
        dataLayerName: GTMDataLayers.defaultDataLayer,
      });
    },
    trackScreenView: (payload): void => {
      const event = {
        name: AnalyticsAction.SCREEN_VIEW,
        payload,
      };

      const eventPayload = event.payload;
      googleTagManager({
        event: 'screen_view',
        path: eventPayload.path || window.location.pathname,
        user_id: eventPayload.user_id || '',
        last_purchase: eventPayload.last_purchase || '',
        average_purchase: eventPayload.average_purchase || '',
        purchases: eventPayload.purchases || '',
      });
    },
    trackEvent: (action): void => {
      switch (action.type) {
        case AnalyticsAction.LOGIN: {
          const { payload } = action;
          googleTagManager({
            event: 'logged_in',
            id: payload.intercomUserId,
            email: hashSHA256(payload.email),
            ...(!!payload.phoneNumber && {
              phone: hashSHA256(payload.phoneNumber),
            }),
          });
          break;
        }
        case AnalyticsAction.PROJECT_DETAIL_TAB_OPENED: {
          const { payload } = action;
          googleTagManager({
            event: 'project_detail_tab_opened',
            path: payload.path,
            tab: payload.tab,
          });
          break;
        }
        case AnalyticsAction.INVEST: {
          googleTagManager({ ecommerce: null }); // Recommended to clear previous ecommerce data, https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#clear-ecommerce
          googleTagManager(createPurchaseEvent(action.payload));
          break;
        }
        case AnalyticsAction.INVEST_OVERVIEW: {
          googleTagManager({ ecommerce: null }); // Recommended to clear previous ecommerce data, https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#clear-ecommerce
          googleTagManager(createAddToCartEvent(action.payload));
          break;
        }
        case AnalyticsAction.WITHDRAW: {
          googleTagManager({ ecommerce: null }); // Recommended to clear previous ecommerce data, https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#clear-ecommerce
          googleTagManager(createWithdrawEvent(action.payload));
          break;
        }
        case AnalyticsAction.REGISTRATION_STEP: {
          const { name, payload } = createRegistrationEvent(action);
          googleTagManager({
            event: name,
            ...payload,
          });
          break;
        }
        case AnalyticsAction.VIEW_ITEM: {
          const { payload } = action;
          googleTagManager({
            event: AnalyticsAction.VIEW_ITEM,
            ecommerce: {
              items: [
                {
                  item_name: payload.project.name,
                  item_id: payload.project.investmentRound.id,
                  item_category: payload.project.investmentRound.purpose,
                  ...(getItemPurposesPayload(payload.project.investmentRound.purposes) || {}),
                },
              ],
            },
          });
          break;
        }
        default:
          googleTagManager({
            event: action.type,
            ...(action as PayloadAction<string, any>).payload,
          });
      }
    },
  };
};
