import Big from 'big.js';

import { futureValueCalculator } from '../math';

interface ChartParams {
  initialInvestment: number;
  annualRateDecimal: number;
  compoundFrequency: number;
  years: number;
  regularPayment: number;
  regularPaymentFrequency: number;
}

type ChartData = { x: number; y: number; year: string };

export function getCompoundInterestChartData({
  initialInvestment,
  annualRateDecimal,
  compoundFrequency,
  years,
  regularPayment,
  regularPaymentFrequency,
}: ChartParams): { data: ChartData[]; interestData: ChartData[] } {
  const data: ChartData[] = [];
  const interestData: ChartData[] = [];

  for (let month = 0; month <= years * 12; month += 1) {
    const term = Big(month).div(12);
    const futureValue = futureValueCalculator({
      initialInvestment,
      annualRateDecimal,
      compoundFrequency,
      term,
      regularPayment,
      regularPaymentFrequency,
    });

    // filter data by every 2 years (= 24 months)
    if (month % 24 === 0) {
      const year = `${Math.floor(month / 12) + new Date().getFullYear()}`;
      data.push({
        x: month,
        y: new Big(futureValue.futureValue).minus(futureValue.totalInterest).toNumber(),
        year,
      });
      interestData.push({ x: month, y: futureValue.totalInterest.toNumber(), year });
    }
  }

  return { data, interestData };
}
