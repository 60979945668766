/* eslint-disable @nx/enforce-module-boundaries */
import { IconName } from '../../../../ui-utils/src/lib/icons/icomoon/icons';
import * as CoreSDK from '../../../../api-sdk/src/lib/core/__generated__/sdk';
import { colorTokens } from '../colors';

export const getActivityType = (
  type: CoreSDK.PropertyInvestmentRoundActivityType | undefined
): CoreSDK.PropertyInvestmentRoundActivityType => {
  if (type === undefined) {
    return CoreSDK.PropertyInvestmentRoundActivityType.Neutral;
  }

  return Object.values(CoreSDK.PropertyInvestmentRoundActivityType).includes(type)
    ? type
    : CoreSDK.PropertyInvestmentRoundActivityType.Neutral;
};

export const isCustomActivity = (source: CoreSDK.PropertyInvestmentRoundActivitySource | undefined): boolean => {
  return source === CoreSDK.PropertyInvestmentRoundActivitySource.InvestownActivity;
};

export const getIconName = (type: CoreSDK.PropertyInvestmentRoundActivityType | undefined): IconName => {
  switch (type) {
    case CoreSDK.PropertyInvestmentRoundActivityType.Positive:
      return 'check';
    case CoreSDK.PropertyInvestmentRoundActivityType.Info:
      return 'info-regular';
    case CoreSDK.PropertyInvestmentRoundActivityType.Negative:
      return 'danger-regular';
    case CoreSDK.PropertyInvestmentRoundActivityType.Neutral:
    default:
      return 'check';
  }
};

export const getCircleColor = (type: CoreSDK.PropertyInvestmentRoundActivityType | undefined): string => {
  switch (type) {
    case CoreSDK.PropertyInvestmentRoundActivityType.Positive:
      return colorTokens.utility.success.medium;
    case CoreSDK.PropertyInvestmentRoundActivityType.Info:
      return colorTokens.icon.brand;
    case CoreSDK.PropertyInvestmentRoundActivityType.Negative:
      return colorTokens.utility.warning.medium;
    case CoreSDK.PropertyInvestmentRoundActivityType.Neutral:
    default:
      return colorTokens.surface.inverted800;
  }
};
