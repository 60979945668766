import Big, { RoundingMode } from 'big.js';
import { IntlShape } from 'react-intl';

import { intlLocaleCrossPlatform } from '../string';

interface PercentFormatOptions {
  input: number | string;
  locale: string;
}

interface CurrencyFormatOptions {
  input: number | string;
  currency: string;
  locale: string;
  fraction?: number;
  showCurrency?: boolean;
}

export interface CurrencyFormatParts {
  value: string;
  currency: string;
}

export function currencyFormat({
  input,
  currency,
  locale,
  fraction = 2,
  showCurrency = true,
}: CurrencyFormatOptions): string {
  const inputNumber = typeof input === 'string' ? parseFloat(input.replace(',', '.').replace(/\s/g, '')) : input;
  const fractionNumber = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const number = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  });

  // Show/hide currency string
  const isWithCurrency = (inputValue: string): string => {
    if (showCurrency) {
      return inputValue.trimEnd().trimStart();
    }
    return inputValue
      .replace(/[^0-9.,-\s]/g, '')
      .trimEnd()
      .trimStart();
  };

  // Show 2 fraction digits if needed
  if (inputNumber % 1 === 0) {
    return isWithCurrency(fractionNumber.format(Number(inputNumber)));
  }
  return isWithCurrency(number.format(Number(inputNumber)));
}

export function getCurrencyFormatParts({
  input,
  currency,
  locale,
  fraction = 2,
}: CurrencyFormatOptions): CurrencyFormatParts {
  const inputNumber = typeof input === 'string' ? parseFloat(input.replace(',', '.').replace(/\s/g, '')) : input;
  const fractionNumber = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const number = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  });

  let inputFormattedIntoParts = number.formatToParts(Number(inputNumber));
  if (inputNumber % 1 === 0) {
    inputFormattedIntoParts = fractionNumber.formatToParts(Number(inputNumber));
  }

  const value = inputFormattedIntoParts
    .map((item) => (item.type === 'currency' ? '' : item.value))
    .reduce((previousValue, currentValue) => previousValue + currentValue)
    .trimEnd();

  const currencyProps = inputFormattedIntoParts.find((item) => item.type === 'currency');

  if (currencyProps?.value) {
    return { value, currency: currencyProps.value };
  }
  return { value, currency: '' };
}

export function percentFormat({ input, locale }: PercentFormatOptions): string {
  const parsedInput = new Big(input);
  const options: Intl.NumberFormatOptions = { style: 'percent' };

  if (Number.isInteger(Number(parsedInput.times(100)))) {
    options.maximumFractionDigits = 0;
    options.minimumFractionDigits = 0;
  } else {
    options.maximumFractionDigits = 2;
    options.minimumFractionDigits = 1;
  }

  return Intl.NumberFormat(locale, options).format(Number(parsedInput));
}

export function countPercentageValue(receivedValue: number, expectedValue: number): number {
  // TODO: Make this by using Big.js based on final data from BE
  return (receivedValue / expectedValue) * 100;
}

export function calculatePercent(value: number): number {
  const valueBig = new Big(value); // e.g. 0.9
  return valueBig.times(100).toNumber(); // e.g. 90
}

export function valueToPercent({
  value,
  total,
  fraction,
  roundingMode,
}: {
  value: number;
  total: number;
  fraction: number;
  roundingMode?: RoundingMode;
}): number {
  const bigValue = new Big(value);
  const bigTotal = new Big(total);

  const amountDivided = bigValue.div(bigTotal);
  const availablePercentageAmount = amountDivided.times(100);

  return Number(availablePercentageAmount.toFixed(fraction, roundingMode));
}

export function minus(minusFrom: number, minusValue: number): number {
  const bigMinusFrom = new Big(minusFrom);
  const bigMinusValue = new Big(minusValue);

  return Number(bigMinusFrom.minus(bigMinusValue).toFixed());
}

export function plus(plusA: number, plusB: number): number {
  const bigPlusA = new Big(plusA);
  const bigPlusB = new Big(plusB);

  return Number(bigPlusA.plus(bigPlusB).toFixed());
}

export function randomInRange(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

export function numberWithDecimal(numberInput: number | string, decimals = 1): string {
  const number = new Big(numberInput);
  return number.round(decimals).toFixed(decimals);
}

export function numberDecimalIntl(numberInput: number | string, locale: string): string {
  const locale2char = intlLocaleCrossPlatform(locale);
  if (locale2char === 'cs') {
    return numberInput
      .toString()
      .replace(/[^0-9.,]/g, '')
      .replace('.', ',');
  }
  if (locale2char === 'en') {
    return numberInput
      .toString()
      .replace(/[^0-9.,]/g, '')
      .replace(',', '.');
  }
  return numberInput.toString();
}

export function numberWithSpaceSeparator(
  input: string | number | undefined | null,
  locale: string,
  isWithEnCommaSeparator?: boolean,
  customThousandsSeparator?: string
): string {
  if (input == undefined) {
    return 'undefined';
  }
  const locale2char = intlLocaleCrossPlatform(locale);

  const decimalSeparator = locale2char === 'cs' ? '.' : ',';
  let thousandsSeparator = ' ';
  isWithEnCommaSeparator && locale2char === 'en' && (thousandsSeparator = ',');
  customThousandsSeparator && (thousandsSeparator = customThousandsSeparator);

  const numberStr = numberDecimalIntl(input, locale2char);

  const [integerPart, decimalPart] = numberStr.split(decimalSeparator);

  // Format the integer part with space separators
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

  // Join the integer part with the decimal part (if present)
  const formattedNumber = decimalPart ? `${formattedInteger}${decimalSeparator}${decimalPart}` : formattedInteger;

  return formattedNumber;
}

export function removeNumberSpaceSeparator(value: string, locale: string): string {
  const locale2char = intlLocaleCrossPlatform(locale);
  if (locale2char === 'cs') {
    return value
      .replace(/\s/g, '') // remove spaces
      .replace(/(,)(?=.*,)/g, '') // remove commas in numbers, but keep the last one
      .replace(/,/g, '.'); // replace commas with dots
  }
  if (locale2char === 'en') {
    return value
      .replace(/\s/g, '') // remove spaces
      .replace(/,/g, ''); // remove commas
  }
  return value;
}

export function stringToFloat(value: string): number {
  return parseFloat(value.replace(/\s/g, '').replace(/,/g, '.'));
}

export function deFormattedStringToFloat(value: string, intl: IntlShape): number {
  return parseFloat(removeNumberSpaceSeparator(value.toString(), intl.locale));
}

export const isNumericInput = (input: string): boolean => /^\d*$/.test(input);

export function calculatePercentWithRound({
  repaid,
  total,
  round,
}: {
  repaid: number;
  total: number;
  round?: 'up' | 'down';
}): number {
  const bigRepaid = new Big(repaid);
  const bigTotal = new Big(total);

  let percent = bigTotal.gt(0) ? bigRepaid.div(bigTotal).times(100) : new Big(0);

  if (round) {
    percent = bigTotal.gt(0)
      ? bigRepaid
          .div(bigTotal)
          .times(100)
          .round(0, round === 'up' ? 3 : 0)
      : new Big(0);
  }

  return percent.toNumber();
}

export function hideDecimals(input: number, number = 1): number {
  if (input >= number) {
    return Math.trunc(input);
  }
  return input;
}

export function returnOnInvestmentFloor({
  amount = 0,
  annualPercentageYield,
  endPercentageBonus = 0,
  investmentLengthInMonths,
}: {
  amount?: number;
  annualPercentageYield: number;
  endPercentageBonus?: number;
  investmentLengthInMonths: number;
}): number {
  const bigAmount = new Big(amount);
  const bigAnnualPercentageYield = new Big(annualPercentageYield);
  const bigEndPercentageBonus = new Big(endPercentageBonus);
  const bigInvestmentLengthInMonths = new Big(investmentLengthInMonths);

  const monthlyYield = bigAnnualPercentageYield.div(12);

  const yieldCalculation = bigAmount.times(monthlyYield).times(bigInvestmentLengthInMonths);
  const bonusCalculation = bigAmount.times(bigEndPercentageBonus);

  const result = yieldCalculation.plus(bonusCalculation);
  return Math.floor(result.toNumber());
}

export function calculateBonusYield({
  annualPercentageYield,
  bonusYield,
}: {
  annualPercentageYield: number;
  bonusYield: number;
}): number {
  const annualYieldBig = Big(annualPercentageYield);
  const bonusYieldBig = Big(bonusYield);
  return Number(annualYieldBig.plus(bonusYieldBig));
}

export function getTotalBonusYield({
  bonusYield,
  annualPercentageYield,
}: {
  bonusYield: number | undefined;
  annualPercentageYield: number;
}): number | undefined {
  if (!bonusYield) {
    // bonusYield is 0 or undefined
    return undefined;
  }
  return calculateBonusYield({
    annualPercentageYield,
    bonusYield,
  });
}

export function getEligibleTotalBonusYield({
  bonusYield,
  annualPercentageYield,
  bonusYieldEligible,
}: {
  bonusYield: number | undefined;
  annualPercentageYield: number;
  bonusYieldEligible: boolean;
}): number | undefined {
  const calculatedTotalBonusYield = getTotalBonusYield({
    annualPercentageYield,
    bonusYield,
  });
  if (bonusYieldEligible && calculatedTotalBonusYield !== undefined) {
    return calculatedTotalBonusYield;
  }
  return undefined;
}
