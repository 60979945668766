import { apiGet } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { UserLevelsConfigDTO } from './model';

export async function getUserLevels(): Promise<UserLevelsConfigDTO> {
  const response = await apiGet<UserLevelsConfigDTO>(ApiNames.MonorepoApiEndpoint, ApiPath.UserLevels, true);

  if (response === undefined) {
    throw new Error('getUserLevels: Invalid response');
  }

  return response;
}
