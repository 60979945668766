import React, { FC } from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';

import { StrokeColorName } from '@investown/fe/common-utils';

// Fix 0 width case -> https://www.npmjs.com/package/react-loading-skeleton#troubleshooting
interface Props extends SkeletonProps {
  parentFlex?: boolean;
  fullWidth?: boolean;
  color?: StrokeColorName;
}

const LoadingSkeleton: FC<Props> = ({ parentFlex, color, fullWidth, ...props }) => {
  let customClass;

  if (parentFlex) {
    customClass = 'skeleton-flex-1';
  } else if (fullWidth) {
    customClass = 'skeleton-full-width';
  }
  return (
    <StyledSkeleton containerTestId="loading-skeleton" color={color} containerClassName={customClass} {...props} />
  );
};

const StyledSkeleton = styled(Skeleton)<{ color?: StrokeColorName }>`
  ${({ theme, color }) =>
    color &&
    `
  background-color: ${theme.colorTokens.stroke[color]};
      --base-color: ${theme.colorTokens.stroke[color]};
  `}
`;

export default LoadingSkeleton;
