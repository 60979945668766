import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  width?: string;
  height?: string;
  className?: string;
}

const Loading: FC<Props> = ({ width, height, className }) => (
  <LoadingContainer width={width} height={height} className={className}>
    <Spinner width={width} height={height} />
  </LoadingContainer>
);

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingContainer = styled.div<Props>`
  height: ${(props) => (props.height ? `${props.height}` : '300px')};
  width: ${(props) => (props.width ? `${props.width}` : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div<Props>`
  height: ${(props) => (props.height ? `${props.height}` : '40px')};
  width: ${(props) => (props.width ? `${props.width}` : '40px')};
  max-height: 40px;
  max-width: 40px;
  border: 4px solid ${({ theme }) => theme.colorTokens.surface.strong};
  border-top: 4px solid ${({ theme }) => theme.colorTokens.icon.brand};
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite;
`;

export default Loading;
