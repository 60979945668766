import { SagaIterator } from 'redux-saga';
import { all, call, fork, takeEvery } from 'redux-saga/effects';

import { queryClient, QueryKeys } from '@investown/fe/api-sdk';

import {
  abilityToBearLossQuestionnaireCompleteAction,
  verificationInvestorQuestionnaireSubmittedAction,
  verificationSubmittedAction,
} from './actions';

import { clearSessionUrl } from 'components/VeriffOpenButton/utils';

function* resetCaches(): SagaIterator {
  yield all([
    call([queryClient, queryClient.invalidateQueries], QueryKeys.UserVerificationData),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.UserDetails),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.UserTimestamps),
    call(clearSessionUrl),
  ]);
}

export function* watchVerificationSubmittedAction(): SagaIterator {
  yield takeEvery(verificationSubmittedAction, resetCaches);
}

export function* watchQuestionnaireSubmittedAction(): SagaIterator {
  yield takeEvery(verificationInvestorQuestionnaireSubmittedAction, resetCaches);
}

export function* watchAbilityToBearLossSubmittedAction(): SagaIterator {
  yield takeEvery(abilityToBearLossQuestionnaireCompleteAction, resetCaches);
}

export default function* root(): SagaIterator {
  yield all([
    fork(watchVerificationSubmittedAction),
    fork(watchQuestionnaireSubmittedAction),
    fork(watchAbilityToBearLossSubmittedAction),
  ]);
}
