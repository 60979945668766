// eslint-disable-next-line @nx/enforce-module-boundaries
import { UserVerificationStatus } from '../../../../api-sdk/src/lib/users';

export function canUserEnterVerification(verificationStatus?: UserVerificationStatus): boolean {
  return !(
    verificationStatus === UserVerificationStatus.WaitingForApproval ||
    verificationStatus === UserVerificationStatus.Verified ||
    verificationStatus === UserVerificationStatus.HasOutdatedVerificationVersion
  );
}
