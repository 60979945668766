import { useQuery, UseQueryResult } from 'react-query';

import { getInstallments } from '../core/getInstallments';
import { PageRequest } from '../core/model';
import { InstallmentFilterType, InstallmentsQuery } from '../core/__generated__/sdk';
import { QueryKeys } from '../enums';

interface InstallmentsRequest extends PageRequest {
  propertyInvestmentRoundId: string;
  filter?: InstallmentFilterType;
}

export function useInstallments({
  propertyInvestmentRoundId,
  page = 0,
  perPage = 100,
  filter,
  enabled,
  onApiCall,
}: {
  propertyInvestmentRoundId: string;
  page?: number;
  perPage?: number;
  filter?: InstallmentFilterType;
  enabled?: boolean;
  onApiCall?: () => void;
}): UseQueryResult<InstallmentsQuery, Error> {
  return useQuery<InstallmentsQuery, Error, InstallmentsQuery, [string, InstallmentsRequest]>(
    [QueryKeys.Installments, { propertyInvestmentRoundId, page, perPage, filter }],
    ({ queryKey: [, variables] }) => {
      if (onApiCall) {
        onApiCall();
      }
      return getInstallments({
        propertyInvestmentRoundId: variables.propertyInvestmentRoundId,
        page: variables.page,
        perPage: variables.perPage,
        filter: variables.filter,
      });
    },
    {
      enabled,
      staleTime: 0,
    }
  );
}
