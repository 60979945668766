import { hashSHA256 } from '../analyticsHelpers';
import { InvestownActionCreators, ScreenViewPayload } from '../analyticsService';
import {
  InvestEventPayload,
  InvestOverviewPayload,
  ProjectDetailTabOpenedEventPayload,
  RegistrationStepEventPayload,
} from '../types';
import { getItemPurposesPayload } from '../Firebase/firebaseEvents';

// Event payload types for Google Tag Manager
export type EcommerceItem = {
  item_name: string;
  item_id: string;
  item_category: string;
  price?: string; // optional, only for purchase event
};

// event: 'view_item';
export type ViewItemEventPayload = {
  ecommerce: {
    items: EcommerceItem[];
  };
};

export type GTMScreenViewPayload = ScreenViewPayload & {
  path: string;
  userId: string;
  lastPurchase: string;
  averagePurchase: string;
  purchases: string;
};
export interface ScreenViewGTMEventPayload {
  path: string;
  user_id: string;
  last_purchase: string;
  average_purchase: string;
  purchases: string;
}

export type GTMEvent<T> = {
  name: EventNames;
  payload: T;
};

export enum EventNames {
  ADD_TO_CART = 'add_to_cart',
  INVEST = 'purchase',
  REGISTRATION_STEP = 'registration_step',
  SCREEN_VIEW = 'screen_view',
  VIEW_ITEM = 'view_item',
  REFUND = 'refund',
  PROJECT_DETAIL_TAB_OPENED = 'project_detail_tab_opened',
}

export type EcommerceEvent<Name extends EventNames, Payload> = {
  event: Name;
  ecommerce: {
    currency?: string;
    value?: string;
    items: [Payload];
  };
};

export interface Events {
  [EventNames.REGISTRATION_STEP]: GTMEvent<RegistrationStepEventPayload>;
  [EventNames.SCREEN_VIEW]: GTMEvent<ScreenViewGTMEventPayload>;
  [EventNames.PROJECT_DETAIL_TAB_OPENED]: GTMEvent<ProjectDetailTabOpenedEventPayload>;
}

export function createAddToCartEvent(
  payload: InvestOverviewPayload
): EcommerceEvent<EventNames.ADD_TO_CART, EcommerceItem> {
  return {
    event: EventNames.ADD_TO_CART,
    ecommerce: {
      items: [
        {
          item_name: payload.project.name,
          item_id: payload.project.currentInvestmentRound?.id || '',
          item_category: payload.project.currentInvestmentRound?.purposes[0] || '',
          ...(getItemPurposesPayload(payload.project.investmentRound.purposes) || {}),
        },
      ],
    },
  };
}

export function createPurchaseEvent(payload: InvestEventPayload): EcommerceEvent<EventNames.INVEST, EcommerceItem> {
  const { currency, amount, project } = payload;
  return {
    event: EventNames.INVEST,
    ecommerce: {
      currency,
      value: amount.toString(),
      items: [
        {
          item_name: project.name,
          item_id: project.currentInvestmentRound?.id || '',
          item_category: project.currentInvestmentRound?.purposes[0] || '',
          price: amount.toString(),
          ...(getItemPurposesPayload(project.investmentRound.purposes) || {}),
        },
      ],
    },
  };
}

export function createWithdrawEvent(payload: InvestEventPayload): EcommerceEvent<EventNames.REFUND, EcommerceItem> {
  const { currency, amount, project } = payload;
  return {
    event: EventNames.REFUND,
    ecommerce: {
      currency,
      value: amount.toString(),
      items: [
        {
          item_name: project.name,
          item_id: project.currentInvestmentRound?.id || '',
          item_category: project.currentInvestmentRound?.purposes[0] || '',
          price: amount.toString(),
          ...(getItemPurposesPayload(project.investmentRound.purposes) || {}),
        },
      ],
    },
  };
}

export function createRegistrationEvent(
  event: ReturnType<InvestownActionCreators['registrationStepAction']>
): Events[EventNames.REGISTRATION_STEP] {
  const { payload } = event;
  return {
    name: EventNames.REGISTRATION_STEP,
    payload: {
      step: payload.step,
      user_type: payload.user_type,
      ...(!!payload.user_id && { user_id: payload.user_id }),
      ...(!!payload.email && { email: hashSHA256(payload.email) }),
      ...(!!payload.phone && { phone: hashSHA256(payload.phone) }),
    },
  };
}

export function createScreenViewEventPayload(payload: GTMScreenViewPayload): ScreenViewGTMEventPayload {
  const { path, userId, lastPurchase, averagePurchase, purchases, ...rest } = payload;
  return {
    path,
    user_id: userId,
    last_purchase: lastPurchase,
    average_purchase: averagePurchase,
    purchases,
    ...rest,
  };
}
