/* eslint-disable @nx/enforce-module-boundaries */
import { UserVerificationDataDTO } from '../../../../api-sdk/src/lib/userVerification/model';
import {
  LegalEntityVerificationVersion,
  UserDetailsDTO,
  UserVerificationStatus,
  UserVerificationVersion,
} from '../../../../api-sdk/src/lib/users';

interface NewContractAvailabilityProps {
  userVerificationData: UserVerificationDataDTO | undefined;
  userDetailsData: UserDetailsDTO | undefined;
  personalEntityExpectedVersion?: UserVerificationVersion;
  legalEntityExpectedVersion?: LegalEntityVerificationVersion;
}
export function getNewContractAvailability({
  userVerificationData,
  userDetailsData,
  personalEntityExpectedVersion = UserVerificationVersion.WithConfirmedContract,
  legalEntityExpectedVersion = LegalEntityVerificationVersion.WithConfirmedContract,
}: NewContractAvailabilityProps): boolean {
  if (
    !userDetailsData ||
    !userVerificationData ||
    (userDetailsData && userDetailsData.verificationStatus !== UserVerificationStatus.Verified)
  ) {
    return false;
  }
  const personalEntityVersion =
    !!userVerificationData?.version && userVerificationData?.version < personalEntityExpectedVersion;
  const legalEntityVersion =
    !!userVerificationData?.companyVerificationVersion &&
    userVerificationData?.companyVerificationVersion < legalEntityExpectedVersion;

  return personalEntityVersion || legalEntityVersion;
}
