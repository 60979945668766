import { useCallback, useState } from 'react';
import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export function useWalletReserveCard(
  config:
    | {
        maximum: number;
        sliderMin: number;
        sliderMax: number;
        default: number;
      }
    | undefined,
  inputFormatter: (value: string) => string = defaultInputFormatter,
  inputResolver: UseFormProps<{ walletReserveInput: string }>['resolver'] = yupResolver(positiveIntegerSchema)
): {
  value: number;
  enabled: boolean;
  onEnabledChanged: (value: boolean) => void;
  onValueChanged: (value: number) => void;
  id: number;
  reset: () => void;
  didChange: () => boolean;
  inputForm: UseFormReturn<{ walletReserveInput: string }>;
  inputVisible: boolean;
  setInputVisible: () => void;
} {
  const walletReserveForm = useForm<{ walletReserveInput: string }>({
    mode: 'onChange',
    context: { min: 0, max: config?.maximum || 999999 },
    resolver: inputResolver,
    defaultValues: {
      walletReserveInput: inputFormatter(config?.default.toString() ?? '0'),
    },
  });
  const resetWalletReserveInput = useCallback(
    (value: string = config?.default.toString() ?? '0') => {
      walletReserveForm.reset({ walletReserveInput: inputFormatter(value) });
    },
    [config?.default, inputFormatter, walletReserveForm]
  );
  const [walletReserveValue, setWalletReserveValue] = useState(config?.default ?? 0);
  const [walletReserveEnabled, setWalletReserveEnabled] = useState(!!config?.default);
  const [walletReserveResetId, setWalletReserveResetId] = useState(0);
  const [walletReserveInputVisible, setWalletReserveInputVisible] = useState(false);
  const resetWalletReserve = useCallback(() => {
    setWalletReserveResetId((prev) => prev + 1);
    resetWalletReserveInput();
    setWalletReserveEnabled(config?.default !== 0);
    setWalletReserveValue(config?.default ?? 0);
    setWalletReserveInputVisible(false);
  }, [config?.default, resetWalletReserveInput]);

  const userChangedWalletReserve = (): boolean => {
    return (
      walletReserveInputVisible ||
      config?.default !== walletReserveValue ||
      (config?.default !== 0) !== walletReserveEnabled
    );
  };

  return {
    value: walletReserveValue,
    enabled: walletReserveEnabled,
    onEnabledChanged: (isEnabled) => {
      resetWalletReserveInput();
      setWalletReserveEnabled(isEnabled);
      setWalletReserveValue(isEnabled ? config?.default ?? 0 : 0);
      setWalletReserveInputVisible(false);
    },
    onValueChanged: (value) => setWalletReserveValue(value),
    id: walletReserveResetId,
    reset: resetWalletReserve,
    didChange: userChangedWalletReserve,
    inputForm: walletReserveForm,
    setInputVisible: () => {
      resetWalletReserveInput(walletReserveValue.toString());
      setWalletReserveInputVisible(true);
    },
    inputVisible: walletReserveInputVisible,
  };
}

function defaultInputFormatter(value: string): string {
  return value;
}

export const positiveIntegerSchema = yup.object().shape({
  walletReserveInput: yup
    .number()
    .typeError('common.validation.positiveInteger')
    .positive('common.validation.positiveInteger')
    .min(yup.ref<number>('$min'), 'common.validation.min')
    .max(yup.ref<number>('$max'), 'common.validation.max'),
});
