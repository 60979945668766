import React, { Suspense } from 'react';
import styled from 'styled-components';
import { LoadableComponent } from '@loadable/component';

import Loading from 'components/V2/Loading/Loading';

export function withSuspense<T extends JSX.IntrinsicAttributes>(
  WrappedComponent: LoadableComponent<NonNullable<unknown>> | React.ComponentType<T>
): React.ComponentType<T> {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithSuspense: React.FC<T> = (props) => (
    <Suspense fallback={<Loader />}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <WrappedComponent {...props} />
    </Suspense>
  );
  ComponentWithSuspense.displayName = displayName;

  return ComponentWithSuspense;
}

const Loader = styled(Loading)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
