import { InstallmentsQuery } from './__generated__/sdk';
import { InstallmentFilterType } from './__generated__/types';
import { getCoreClient } from './client';

export interface GetInstallmentsProps {
  propertyInvestmentRoundId: string;
  page: number;
  perPage: number;
  filter?: InstallmentFilterType;
}

export async function getInstallments({
  page,
  perPage,
  filter,
  propertyInvestmentRoundId,
}: GetInstallmentsProps): Promise<InstallmentsQuery> {
  const client = await getCoreClient();
  return client.Installments({ page, perPage, filter, propertyInvestmentRoundId });
}
